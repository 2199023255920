
import validator from 'validator';

const validateCreateBulletinManagement = (data) => {
    let errors = {};

    const { title, description, category_id,country_id} = data;

    if (validator.isEmpty(title)) {
        errors.title = '제목은 필수 항목입니다.';
    }
    if (validator.isEmpty(description)) {
        errors.description = '내용은 필수 항목입니다.';
    }
    
    if (validator.isEmpty(category_id)) {
        errors.category_id = '카테고리를 선택하세요.';
    }
    if (validator.isEmpty(country_id)) {
        errors.country_id = '국가를 선택하세요.';
    }
    // if (validator.isEmpty(file)) {
    //     errors.file = 'please upload the file';
    // }

    // if (validator.isEmpty(status)) {
    //     errors.status = 'please upload the file';
    // }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default validateCreateBulletinManagement;