<template>
    <div class="card">
        <Toast />
        <!-- <loader :active="loaderActive" message="Please wait 5 seconds" /> -->
        <form>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('Bulletins.AddBulletin.Heading') }}</h5>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Bulletins.AddBulletin.Title') }}</label>
                            <InputText type="text" :class="`${error.title ? 'p-invalid' : ''}`" :placeholder="$t('Bulletins.AddBulletin.TitlePlace')" v-model="title" id="title2" @keydown.space="preventLeadingSpace" maxlength="75"></InputText>
                            <div class="text-red">{{ error.title }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Bulletins.AddBulletin.Description') }}</label>
                            <Textarea
                                type="text"
                                :class="`${error.description ? 'p-invalid' : ''}`"
                                :placeholder="$t('Bulletins.AddBulletin.DescriptionPlace')"
                                v-model="description"
                                id="title2"
                                @keydown.space="preventLeadingSpace"
                                maxlength="1500"
                            ></Textarea>
                            <div class="text-red">{{ error.description }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Bulletins.AddBulletin.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id" optionLabel="country_name" :placeholder="$t('Bulletins.AddBulletin.Select')" :class="`${error.country_id ? 'p-invalid' : ''}`" />
                            <div class="text-red">{{ error.country_id }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Bulletins.AddBulletin.Category') }}</label>
                            <Dropdown
                                v-model="category_id"
                                :options="dropdownBoardCategoryValues"
                                optionValue="id"
                                optionLabel="title"
                                :placeholder="$t('Bulletins.AddBulletin.Select')"
                                @click="getCategory"
                                :class="`${error.category_id ? 'p-invalid' : ''}`"
                            />
                            <div class="text-red">{{ error.category_id }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="subtitle2">
                                {{ $t('Bulletins.AddBulletin.Upload') }}
                                <!-- <span class="img-info">(파일 형식: jpg,jpeg,png )(이미지 너비 924px and 고저 860px 및 크기 1MB )</span> -->
                            </label>
                            <div :class="`${error.file ? 'custom-select-invalid' : 'custom-select'}`">
                                <span v-if="!fileName">{{ $t('Bulletins.AddBulletin.fileupload') }}</span>
                                <span v-else>{{ fileName }}</span>
                                <input type="file" class="select-file" v-on:change="onFileChange" />
                                <Button :label="$t('Bulletins.AddBulletin.ChooseFile')" class="SelectBtn n-wrap" />
                            </div>
                            <div class="text-red">{{ error.file }}</div>
                            <div class="text-red" v-show="render1">유효하지 않은 파일 형식</div>
                            <div class="text-red" v-show="render2">최대 파일 크기는 5MB여야 합니다.</div>

                            <img id="frame" src width="100px" height="100px" />
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Bulletins.AddBulletin.Status') }}</label>
                            <br />
                            <InputSwitch v-model="expose" trueValue="1" />
                            <!-- <div class="text-red">{{ error.url }}</div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-d-flex p-jc-end p-ai-center" style="float: left">
                <Button :label="$t('Bulletins.AddBulletin.Save')" icon="pi pi-check" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="addBulletinManagement"></Button>
            </div>

            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button :label="$t('Bulletins.AddBulletin.Back')" icon="pi pi-replay" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>

            <br />
        </form>
    </div>
</template>

<script>
import axios from 'axios';
// import Loader from '../../../components/Loader.vue'
import BulletinManagementService from '../../../service/API/SuperAdmin/BulletinManagementService.js';
import validateCreateBulletinManagement from '../../../validations/BulletinManagement/validateCreateBulletinManagement';
export default {
    name: 'AddBulletinManagement',
    // components: {
    //     Loader
    // },
    data() {
        return {
            render: true,
            display: false,
            position: 'center',
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
            render1: false,
            file: '',
            files: '',
            fileName: '',
            product_image: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            title: '',
            description: '',
            category_id: '',
            country_id: '',
            image: '',
            expose: '',
            customer1: null,
            loading1: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            status: '',
            error: {},
            id: '',
            render2: false,
            loaderActive: false,
            formData: new FormData(),
        };
    },
    customerService: null,
    productService: null,
    created() {
        this.bulletinManagementService = new BulletinManagementService();
    },
    mounted() {
        this.bulletinManagementService
            .getBulletinManagementList(this.title, this.country_id, this.category_id, this.post_date)
            .then((res) => {
                this.products = res.data.data.board;
                this.total = res.data.data.length;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                console.log(res.data.data.board);
            })

            .catch((err) => console.log(err));

        this.bulletinManagementService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));

        // this.bulletinManagementService
        //     .getBoardCategorydropdown()
        //     .then((data) => {
        //         this.dropdownBoardCategoryValues = data.data.data.board_category;
        //         // this.products = data;
        //         this.loading1 = false;
        //         // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
        //         console.log(this.dropdownBoardCategoryValues);
        //     })
        //     .catch((err) => console.log(err));
    },
    methods: {
        getCategory() {
            this.bulletinManagementService.getBoardCategorydropdown(this.country_id, this.category_type).then((res) => {
                this.dropdownBoardCategoryValues = res.data.data.board_category;
                console.log(res);
            });
        },
        formatDate(value) {
            const date = new Date(value);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            var hr = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (value = `${yyyy}-${mm}-${dd} ${hr}:${min}:${sec}`);
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            // if (!files.length) return;
            this.file = files[0];
            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!allowedExtensions.exec(this.file.name)) {
                this.render1 = true;
                this.render2 = false;
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = (e) => {
                    let url = e.target.result;
                    this.image_url = url;
                };
                this.render1 = false;
                if (this.file.size > 5242880) {
                    // alert('파일이 너무 큽니다  ');
                    this.render2 = true;
                } else {
                    this.render2 = false;
                    this.fileName = this.file.name;
                    this.formData.append('image', files[0]);
                    // this.upload(e)
                }
            }
            this.fileExtension = this.fileName.replace(/^.*\./, '');
            console.log(this.fileName);
        },

        // onFileChange(e) {
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) return;
        //     this.file = files[0];
        //     var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        //     if (!allowedExtensions.exec(this.file.name)) {
        //         this.render1 = true;
        //         return false;
        //     } else {
        //         this.render1 = false;
        //         this.fileName = this.file.name;
        //         this.formData.append('image', files[0]);
        //     }
        //     this.fileExtension = this.fileName.replace(/^.*\./, '');
        //     console.log(this.fileName);
        // },

        addBulletinManagement() {
            // var self = this;
            let vcheckData = {
                title: this.title,
                description: this.description,
                country_id: this.country_id.toString(),
                category_id: this.category_id.toString(),
                expose: this.expose,
                file: this.fileName == '' ? '' : 'something',
            };
            console.log(vcheckData);
            const { isInvalid, error } = validateCreateBulletinManagement(vcheckData);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                this.error = '';
                this.formData.append('title', this.title);
                this.formData.append('description', this.description);
                this.formData.append('country_id', this.country_id);
                this.formData.append('category_id', this.category_id);
                this.formData.append('expose', this.expose == '1' ? '1' : '0');
                // console.log(self.name);
                // this.loaderActive = true;
                return axios
                    .post('/asnhist-dev/api/v1/admin/board/add', this.formData)
                    .then(() => {
                        // alert('아파트가 성공적으로 추가되었습니다.');
                        this.$router.push({ name: 'BulletinManagementList' });
                        setTimeout(() => {
                            this.$toast.add({ severity: 'info', summary: '확인되었습니다.', detail: '뉴스/컨텐츠 게시물이 성공적으로 추가되었습니다.', life: 3000 });
                        }, 1000);
                    })
                    .catch((err) => {
                        // console.log(err.response.data.message);
                        if (err.response.data.message === '제목 존재') {
                            this.$toast.add({ severity: 'error', summary: '거부됨', detail: '제목 존재', life: 3000 });
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        } else if (err.response.data.message === 'Server Error') {
                            this.$toast.add({ severity: 'error', summary: '거부됨', detail: '제목 존재', life: 3000 });
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        }

                        // this.$toast.add({ severity: 'error', summary: '거부됨', detail: err.response.data.message, life: 3000 });
                        // console.log('error',err.response.data.message);
                    });
            }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '');
        },
    },
};
</script>

<style scoped>
.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.img-info {
    font-size: 11px;
    font-weight: 400;
    color: rgb(48, 48, 48);
}

.custom-select {
    position: relative;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.custom-select-invalid {
    position: relative;
    border: 1px solid red;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.select-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    z-index: 5;
    opacity: 0;
}

.SelectBtn {
    max-width: 100px;
}

.custom-select span {
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
